import Layout from "../components/layout"
import SEO from "../components/seo"

import React, { Component } from "react"

import { graphql } from "gatsby"

// import lazySizes from 'lazysizes'


// import AniLink from "gatsby-plugin-transition-link/AniLink"



import "../styles/style.scss"

const getWindowType = (window) => {
    if (typeof window !== `undefined` && window.innerWidth < 450) {
        return 'mobile'
    } else if (typeof window !== `undefined` && window.innerWidth < 1367) {
        return 'tablet'
    } else {
        return 'desktop'
    }
}

class Experimentation extends Component {

    constructor(props) {
        super(props);
        this.modelViewerIntroducion = React.createRef();
        this.introductionElem = React.createRef();

        this.introMouseTimeout = null;
        this.windowType = typeof window !== `undefined` ? getWindowType(window) : 'desktop';


    }

    state = {
        polygonDynamicsAnimation: '',
        poster: '',
        valueLoaded: '0'
    }



    componentDidMount() {
        if (typeof window !== `undefined`) {
            window.hello=() => {document.location.href = "mailto:hello@gaspardbruno.com";console.log("Send us an email!");return "";}
            setTimeout(console.log.bind(console, "Hello, you're interested in what's going on behind? We'd love to hear from you at " +   '%chello@gaspardbruno.com!' + '%c -> ' + '%chello()', 'color: #1812d6', 'color:black', 'font-weight:bold'), 0);
        }
        try {

            require("@google/model-viewer/dist/model-viewer.min")

        } catch (e) {
            console.error(e)
        }

        this.modelViewerIntroducion.current.addEventListener('load', () => {
            this.modelViewerIntroducion.current.cameraOrbit = 'calc(0deg + env(window-scroll-y) * 180deg) calc(0deg + env(window-scroll-y) * 180deg) 260m';
        });


        this.modelViewerIntroducion.current.addEventListener('environment-change', () => {
            // console.log('bg change');
            // this.modelViewerIntroducion.current.exposure = 0;

        });

        this.modelViewerIntroducion.current.addEventListener('load', (event) => {
            console.log('loaded');
            this.setState({
                poster: 'posterOff'
            })
        });

        this.modelViewerIntroducion.current.addEventListener('progress', (event) => {
            const {totalProgress} = event.detail;
            const valueLoaded = Math.round(parseFloat(totalProgress) * 100);
            this.setState({
                valueLoaded: valueLoaded
            })
        });
    }

    handleMouseMove = (e) => {
        if (this.introMouseTimeout) {
            clearTimeout(this.introMouseTimeout)
        }

        const { offsetX, offsetY } = e.nativeEvent;

        const intro = this.introductionElem.current;

        const h = intro.offsetHeight;
        const yPercentage = offsetY / h;
        const xPercentage = offsetX / (typeof window !== `undefined` ? window.innerWidth : 1367);
        const rotatex = (Math.floor(xPercentage * 80)) + 'deg';
        const rotatey = (Math.floor(yPercentage * 160)) + 'deg';
        const orbitCycle = (rotatex + rotatey + '260m');

        this.modelViewerIntroducion.current.cameraOrbit = orbitCycle;

        const lastTimeMouseMoved = new Date().getTime();
        this.introMouseTimeout = setTimeout(() => {
            const currentTime = new Date().getTime();
            if ( this.modelViewerIntroducion.current && currentTime - lastTimeMouseMoved > 500) {
                this.modelViewerIntroducion.current.cameraOrbit = 'calc(0deg + env(window-scroll-y) * 180deg) calc(0deg + env(window-scroll-y) * 180deg) 260m';
            }
        }, 1000);
    }


    element01() {
        try {

            this.modelViewerIntroducion.current.environmentImage = '/experimentation/environment1.jpg';

        } catch (e) {
          console.error(e)
        }
    }
    element02() {
        try {

            this.modelViewerIntroducion.current.environmentImage = '/experimentation/environment2.jpg';

        } catch (e) {
          console.error(e)
        }
    }

    element03() {
        try {

            this.modelViewerIntroducion.current.environmentImage = '/experimentation/environment3.jpg';

        } catch (e) {
          console.error(e)
        }
    }

    element04() {
        try {

            this.modelViewerIntroducion.current.environmentImage = '';

        } catch (e) {
          console.error(e)
        }
    }

    element05() {
        try {

            const modelViewerParameters = this.modelViewerIntroducion;
            console.log(modelViewerParameters);

            let material = modelViewerParameters.current.model.materials[0];

        } catch (e) {
          console.error(e)
        }
    }

    element06() {
        try {
            this.modelViewerIntroducion.current.src = '/chiquito.gltf';

        } catch (e) {
          console.error(e)
        }
    }

    element07() {
        try {
            this.modelViewerIntroducion.current.src = '/draco.glb';

        } catch (e) {
          console.error(e)
        }
    }

    render() {



        return (

        <Layout noFooter>

            <SEO title="Polygon Dynamics"/>

            <div className="polygonDynamicsModel">

                <model-viewer id="sphere" uk-parallax="x: 30%; target: #textIntro;" ref={this.modelViewerIntroducion} interaction-prompt="false" id="modelViewer" min-field-of-view="Infinity" min-camera-orbit="Infinity Infinity Infinity" max-camera-orbit="Infinity Infinity Infinity" max-field-of-view="Infinity" animation-name="toto" shadow-intensity="0" src="/experimentation/draco.glb"></model-viewer>
                <div className={`loader ${this.state.poster}`}>
                    <div className="row row___max">
                        <div className="col col8">
                            <h3>Loading {this.state.valueLoaded}%</h3>
                        </div>
                    </div>
                </div>
            </div>

            <section id="textIntro" className="experimentation" onMouseMove={this.handleMouseMove} ref={this.introductionElem}>
                    <div className="row row___max">
                        <div className="col col8">

                        <div className="buttonGroupExperiementation">
                            <h3 className="LabelExperiementation">environment</h3>
                            <button onClick={() => this.element01()}>
                                    <img src='/experimentation/environment1.jpg' alt=""></img>
                            </button>
                            <button onClick={() => this.element02()}>
                                    <img src='/experimentation/environment2.jpg' alt=""></img>
                            </button>

                            <button onClick={() => this.element03()}>
                                    <img src='/experimentation/environment3.jpg' alt=""></img>
                            </button>

                            <button onClick={() => this.element04()}>
                                    empty
                            </button>
                        </div>

                        <div className="buttonGroupExperiementation">

                            <h3 className="LabelExperiementation">model</h3>
                            <button onClick={() => this.element06()}>
                                    chiquito
                            </button>
                            <button onClick={() => this.element07()}>
                                    abstract
                            </button>
                        </div>
                        <div className="buttonGroupExperiementation">

                            <h3 className="LabelExperiementation">color</h3>

                            <button onClick={() => this.element05()}>
                                    red
                            </button>
                        </div>
                        </div>
                    </div>
            </section>

        </Layout>

        )
    }
}

export default Experimentation
